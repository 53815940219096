import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import {
    CURRENT_YEAR_TEXT,
    UPCOMING_YEAR_TEXT,
    TABLE_COLUMN_NAME_GROUP,
    REPORT_LOADING_MSG,
    SALARY_FREEZE_BUTTON_TEXT,
    UNAVAIL_DATA_BUTTON_TEXT,
    TEXT_ALL_DATA,
    TEXT_NO_AVAIL_DATA,
    TEXT_DATA_CUTS_SELECTION,
    TEXT_INDUSTRY_VERTICAL,
    TEXT_INDUSTRY_SECTOR,
    TEXT_OWNERSHIP,
    TEXT_HEADCOUNT,
    TEXT_STARTUP,
    BOOLEAN_TEXT_TRUE,
    UPCOMING_YEAR_LIST,
    CURRENT_YEAR_LIST,
    SURVEY_UUID_LIST,
    PRELIM_SURVEY_LIST,
    REPORT_COLUMN_SELECTION_BUTTON_TEXT,
    TEXT_YEAR_REPORT_SELECTION,
    TEXT_COUNTRY_SELECTION,
    TEXT_REPORT_SELECTION,
    TEXT_OTHER_OPTIONS,
    COUNTRY_SELECTED_TEXT,
    TEXT_SELECT_PLACEHOLDER,
    TABLE_COLUMN_NAME_N,
    BOOLEAN_TEXT_FALSE,
    EXPORT_OPTION_AVALIBLE_SURVEY_LIST,
    SURVEY_COPY_RIGHT,
    SURVEY_YEAR_2024_2025_PRELIM,
    SURVEY_YEAR_2024_2025,
    TEXT_ANNOTATION_FOR_N_COUNTRY,
} from '../constants/constants.js'
import {
    REPORT_OPTIONS_PAY_ZONED_COUNTRIES_ALL,
    REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC,
    REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL,
    REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC,
    PAY_ZONED_COUNTRIES,
    ADVANCED_BSI_REPORT_TYPES,
    REPORT_BASE_SALARY_INCREASE,
    REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_VERTICAL,
    REPORT_BASE_SALARY_INCREASE_BY_HEADCOUNT,
    REPORT_BASE_SALARY_INCREASE_BY_OWNERSHIP,
    REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_SECTOR,
    REPORT_BASE_SALARY_INCREASE_BY_LOCALE,
    COUNTRY_SHOW_QUESTION,
    REPORT_BASE_SALARY_INCREASE_BY_PAYZONE,
} from '../constants/countryReportConstants'
import {
    SECTOR_GROUPS,
    VERTICALS,
    OWNERSHIP_GROUPS,
    HEADCOUNT_OPTIONS,
    STARTUP_OPTIONS,
    SECTOR_GROUP_ARR,
    HEADCOUNT_OPTIONS_2024_2025,
} from '../constants/datacutConstants'
import {
    COLUMNS_TO_SHOW_ALL,
    Catalogues,
} from '../constants/reportOuputConstants'
import {
    CheckboxPercentiles,
    getTableHeaderStyle,
    useQueryState,
    RadioButton,
    Checkbox,
    ReactSelect,
    IsValidDataCutSelection,
    IsValidOtherOptions,
    tableFooter,
    updateSectorSelectedValueForDataCutLables,
    getDataCutSelectionTextName,
    getDataCutSelectionTextTitle,
} from '../helpers/helpers'
import { GROUPED_COUNTRY_OPTIONS } from '../constants/countryConstants'
import { formatGroupLabel } from '../docs/style.js'
import {
    REACT_APP_SERVER_ADDRESS,
    REACT_APP_API_KEY,
    REACT_APP_EXCEL_EXPORT,
} from '../config'
import PageNotFound from './404Page'
import { useParams } from 'react-router-dom'
import Popup from 'reactjs-popup'

function Country() {
    return (
        <div>
            <UserInput />
        </div>
    )
}

const UserInput = () => {
    const params = useParams()
    const survey = SURVEY_UUID_LIST[params.survey]
    const [countryValue, setCountryValue] = useQueryState('country')
    const [reportValue, setReportValue] = useQueryState('report')
    const [reportQuestion, setReportQuestion] = useState('')
    const [sectorValue, setSectorValue] = useQueryState('sector')
    const [verticalValue, setVerticalValue] = useQueryState('vertical')
    const [ownershipValue, setOwnershipValue] = useQueryState('ownership')
    const [headcountValue, setHeadcountValue] = useQueryState('headcount')
    const [startupValue, setStartupValue] = useQueryState('startup')
    const [salaryFreeze, setSalaryFreeze] = useQueryState('freeze')
    const [yearValue, setYearValue] = useQueryState('year')
    const [showNonReportableDataValue, setShowNonReportableDataValue] =
        useQueryState('alldata')
    const [showGeoNotes, setShowGeoNotes] = useState(false)
    const [isReportLoading, setIsReportLoading] = useState(false)
    const [reportDropDownOptionList, setReportDropDownOptionList] = useState([])

    const [showSpinner, setShowSpinner] = useState(true)
    const [items, setItems] = useState([])
    const [responses, setResponses] = useState({})

    const COLUMNS_TO_SHOW_MD = COLUMNS_TO_SHOW_ALL.filter(
        (item) => item['enabled'] === BOOLEAN_TEXT_TRUE
    )
    const [isCheckAll, setIsCheckAll] = useState(false)
    const [isCheck, setIsCheck] = useState(
        COLUMNS_TO_SHOW_MD.map((li) => li.value)
    )

    useEffect(() => {
        updateReportQuestionName()
    }, [reportValue, yearValue])

    useEffect(() => {
        if (reportValue === REPORT_BASE_SALARY_INCREASE_BY_PAYZONE) {
            setShowGeoNotes(true)
        } else {
            setShowGeoNotes(false)
        }
    }, [reportValue])

    const [columnsOnTable, setColumnsOnTable] = useState([])

    const [headcountOptions, setHeadcountOptions] = useState(HEADCOUNT_OPTIONS)
    useEffect(() => {
        if (
            [SURVEY_YEAR_2024_2025_PRELIM, SURVEY_YEAR_2024_2025].includes(
                survey
            )
        ) {
            setHeadcountOptions(HEADCOUNT_OPTIONS_2024_2025)
        } else {
            setHeadcountOptions(HEADCOUNT_OPTIONS)
        }
    }, [survey])

    // Initialize isDownloading state variable
    const [isDownloading, setIsDownloading] = useState(false)

    useEffect(() => {
        setShowSpinner(true)

        const changedFields = updateDataFieldsAvailability(reportValue)
        if (changedFields === 0) {
            fetchDataAndUpdateTable()
        }
    }, [
        sectorValue,
        verticalValue,
        reportValue,
        startupValue,
        ownershipValue,
        headcountValue,
        countryValue,
        salaryFreeze,
        yearValue,
        showNonReportableDataValue,
        isCheck,
    ])

    useEffect(() => {
        if (countryValue) {
            setIsReportLoading(true)
            setReportDropDownOptionList([])
            setReportOptionsAvailability()
        }
    }, [countryValue])

    const updateReportQuestionName = () => {
        let yearText = ''
        // if yearValue is Actual, set year to 2024, if it is Projected, set year to 2025, if it is undefined, set year to 2025
        if (yearValue === CURRENT_YEAR_TEXT) {
            yearText = 2024
        } else if (yearValue === UPCOMING_YEAR_TEXT || !yearValue) {
            yearText = 2025
        }
        let questionFound = false

        for (const entry of COUNTRY_SHOW_QUESTION) {
            if (reportValue === entry.report && yearText === entry.year) {
                setReportQuestion(entry.question)
                questionFound = true
                break // Exit the loop once a match is found
            }
        }

        if (!questionFound) {
            setReportQuestion('') // Set question to an empty string if no match is found
        }
    }

    const fetchDataAndUpdateTable = async () => {
        if (!countryValue) {
            return
        }
        const options = {
            method: 'GET',
            url: `${REACT_APP_SERVER_ADDRESS}country`,
            params: {
                year: yearValue,
                zeros: salaryFreeze,
                ownership: ownershipValue,
                country: countryValue,
                sector: sectorValue,
                vertical: verticalValue,
                headcount: headcountValue,
                startup: startupValue,
                report: reportValue,
                alldata: showNonReportableDataValue,
                survey: survey,
                columns: isCheck.join(','),
            },
        }

        try {
            const response = await axios.request(options)
            const respBody = response.data
            setResponses(respBody)
            const newData = respBody.results
            setItems(newData)
            setShowSpinner(false)
            const columnsToShow = COLUMNS_TO_SHOW_ALL.filter(function (obj) {
                return Object.keys(newData[0]).includes(obj.value)
            })
            setColumnsOnTable(columnsToShow)
        } catch (e) {}
    }
    const setReportOptionsAvailability = async () => {
        if (countryValue) {
            const getCountryTotal = {
                method: 'GET',
                url: `${REACT_APP_SERVER_ADDRESS}countrytotal`,
                params: {
                    country: countryValue,
                    survey: survey,
                },
            }
            const response = await axios.request(getCountryTotal)
            const respBody = response.data
            const countryTotal = respBody.count

            if (PAY_ZONED_COUNTRIES.includes(countryValue)) {
                if (countryTotal > 0) {
                    setReportDropDownOptionList(
                        REPORT_OPTIONS_PAY_ZONED_COUNTRIES_ALL[survey]
                    )
                } else {
                    if (ADVANCED_BSI_REPORT_TYPES.includes(reportValue)) {
                        setReportValue(undefined)
                    }
                    setReportDropDownOptionList(
                        REPORT_OPTIONS_PAY_ZONED_COUNTRIES_BASIC[survey]
                    )
                }
            } else {
                if (countryTotal > 0) {
                    setReportDropDownOptionList(
                        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_ALL[survey]
                    )
                } else {
                    if (ADVANCED_BSI_REPORT_TYPES.includes(reportValue)) {
                        setReportValue(undefined)
                    }
                    setReportDropDownOptionList(
                        REPORT_OPTIONS_NON_PAY_ZONED_COUNTRIES_BASIC[survey]
                    )
                }
            }
        }
        setIsReportLoading(false)
    }

    const shouldDisplayErrorText = () => {
        if (showSpinner) {
            return false
        }

        if (reportValue === REPORT_BASE_SALARY_INCREASE) {
            if (showNonReportableDataValue) {
                return rowsWithDataCutsSelectedAndAlsoZeros.length > 0
            } else {
                return (
                    isAnyDataCutsSelected() &&
                    (rowsWithDataCutsSelected.length = 0)
                )
            }
        } else {
            return findReportableData.length === 0
        }
    }

    const isAnyDataCutsSelected = () => {
        return (
            sectorValue ||
            verticalValue ||
            ownershipValue ||
            headcountValue ||
            startupValue
        )
    }

    const handleCountryChange = (event) => {
        setCountryValue(event.value)
    }

    const handleReportOptionChange = (event) => {
        setReportValue(event.value)
    }

    const updateDataFieldsAvailability = (value) => {
        let changed = 0
        if (value === REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_SECTOR) {
            if (sectorValue) {
                setSectorValue(undefined)
                changed++
            }
        } else if (value === REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_VERTICAL) {
            if (verticalValue) {
                setVerticalValue(undefined)
                changed++
            }
        } else if (value === REPORT_BASE_SALARY_INCREASE_BY_OWNERSHIP) {
            if (ownershipValue) {
                setOwnershipValue(undefined)
                changed++
            }
        } else if (value === REPORT_BASE_SALARY_INCREASE_BY_HEADCOUNT) {
            if (headcountValue) {
                setHeadcountValue(undefined)
                changed++
            }
        }
        return changed
    }

    const handleSectorChange = (event) => {
        if (event === null) {
            setSectorValue(undefined)
        } else {
            setSectorValue(event.value)
        }
    }

    const handleVerticalChange = (event) => {
        if (event === null) {
            setVerticalValue(undefined)
        } else {
            setVerticalValue(event.value)
        }
    }

    const handleOwnershipChange = (event) => {
        if (event === null) {
            setOwnershipValue(undefined)
        } else {
            setOwnershipValue(event.value)
        }
    }

    const handleHeadcountChange = (event) => {
        if (event === null) {
            setHeadcountValue(undefined)
        } else {
            setHeadcountValue(event.value)
        }
    }

    const handleStartupChange = (event) => {
        if (event === null) {
            setStartupValue(undefined)
        } else {
            setStartupValue(event.value)
        }
    }

    const handleSalaryFreezeChange = (event) => {
        // if event.target.checked.toString() is true, then setSalaryFreeze to BOOLEAN_TEXT_TRUE, else BOOLEAN_TEXT_FALSE
        event.target.checked.toString() === 'true'
            ? setSalaryFreeze(BOOLEAN_TEXT_TRUE)
            : setSalaryFreeze(BOOLEAN_TEXT_FALSE)
    }
    //console
    const handleShowNonReportableData = (event) => {
        event.target.checked.toString() === 'true'
            ? setShowNonReportableDataValue(BOOLEAN_TEXT_TRUE)
            : setShowNonReportableDataValue(BOOLEAN_TEXT_FALSE)
    }

    const handleActualChange = () => {
        setYearValue(CURRENT_YEAR_TEXT)
    }

    const handleProjectedChange = () => {
        setYearValue(UPCOMING_YEAR_TEXT)
    }

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll)

        if (isCheckAll) {
            setIsCheck(COLUMNS_TO_SHOW_MD.map((li) => li.value))
        } else {
            setIsCheck(COLUMNS_TO_SHOW_ALL.map((li) => li.value))
        }
    }
    const handleClick = (event) => {
        const { id, checked } = event.target
        setIsCheck([...isCheck, id])
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id))
        }
    }
    const catalog = Catalogues.map(({ id, name, disabled }) => {
        return (
            <div>
                <CheckboxPercentiles
                    className="checkbox-container"
                    key={id}
                    type="checkbox"
                    name={name}
                    id={id}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(id)}
                    disabled={disabled}
                />
                <label className="percentile-labels">{name}</label>
            </div>
        )
    })

    function cellStyle(row, column) {
        const row_contains_sector = SECTOR_GROUP_ARR.some((element) => {
            return row[TABLE_COLUMN_NAME_GROUP].toString().trim() === element
        })

        const cell_contains_sector = SECTOR_GROUP_ARR.some((element) => {
            return row[column.value].toString().trim() === element
        })
        const ownership_group = [
            'For-Profit (Publicly Traded + Privately Owned)',
            'Not-For-Profit (Non-Profit + Government)',
        ]
        if (
            row[TABLE_COLUMN_NAME_GROUP].toString().includes(TEXT_ALL_DATA) ||
            ownership_group.includes(row[TABLE_COLUMN_NAME_GROUP])
        ) {
            if (
                ![
                    'All Data',
                    'For-Profit (Publicly Traded + Privately Owned)',
                    'Not-For-Profit (Non-Profit + Government)',
                ].some((text) => row[column.value].toString().includes(text))
            ) {
                return 'td-all-data-numbers'
            } else {
                return 'td-all-data-name'
            }
        } else if (row_contains_sector) {
            if (cell_contains_sector) {
                return 'td-group-name'
            } else {
                return 'td-group-numbers'
            }
        } else {
            return column.style
        }
    }

    const DisplayDataItems = items.map((item) => {
        return (
            <tr>
                {/* {columns_on_table.map((column) => { */}
                {columnsOnTable.map((column) => {
                    return (
                        <th className={cellStyle(item, column)}>
                            {item[column.value] === '*'
                                ? item[column.value]
                                : item[column.value].toString().includes('[') &&
                                  item[column.value].toString().includes(']') &&
                                  reportValue !==
                                      REPORT_BASE_SALARY_INCREASE_BY_LOCALE
                                ? updateSectorSelectedValueForDataCutLables(
                                      item[column.value]
                                  )
                                : item[column.value]
                                      .toLocaleString()
                                      .concat(column.percent)}
                        </th>
                    )
                })}
            </tr>
        )
    })
    const rowsWithDataCutsSelected = items.filter((number) =>
        number[TABLE_COLUMN_NAME_GROUP].includes('Data Cuts Selected')
    )

    const rowsWithDataCutsSelectedAndAlsoZeros = items.filter(
        (item) =>
            item[TABLE_COLUMN_NAME_GROUP].includes('Data Cuts Selected') &&
            item[TABLE_COLUMN_NAME_N] === 0
    )

    const findReportableData = items.filter((item) => {
        return item[TABLE_COLUMN_NAME_N] > 0
    })
    console.log('reportValue', reportValue)
    const DisplayData =
        showSpinner === true ? (
            <div id="loader"></div>
        ) : (
            <div>
                <div>
                    <table className="table table-sm ">
                        <thead>
                            {columnsOnTable.map((column) => {
                                return (
                                    <th className="table-first-row">
                                        {getTableHeaderStyle(column.name)}
                                    </th>
                                )
                            })}
                        </thead>
                        <tbody>{DisplayDataItems}</tbody>
                        {tableFooter(
                            survey,
                            'yes',
                            'no',
                            showGeoNotes,
                            TEXT_ANNOTATION_FOR_N_COUNTRY
                        )}
                    </table>
                </div>
            </div>
        )

    const handleExcelDownload = async () => {
        setIsDownloading(true) // Set isDownloading to true at the start
        // const apiUrl = 'https://dev.private.culpepper.com/API/exportsbs/export'
        const requestData = responses
        requestData['copyright'] = SURVEY_COPY_RIGHT[survey]
        axios
            .post(REACT_APP_EXCEL_EXPORT, requestData, {
                responseType: 'blob', // Set the response type to 'blob' to receive binary data
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${REACT_APP_API_KEY}`,
                    'Content-Type': 'application/json',
                },
                crossdomain: true,
            })

            .then((response) => {
                // Check the status code of the response
                if (response.status !== 200) {
                    // If the status code is not 200, show an alert to the user
                    alert(
                        "The current download is not working, please use the 'Contact Us' on the bottom of the page to report this issue."
                    )
                } else {
                    // Get the "returnData" field from the API response
                    const blob = new Blob([response.data])
                    const fileUrl = URL.createObjectURL(blob)

                    // Create an anchor element
                    const downloadLink = document.createElement('a')
                    downloadLink.href = fileUrl
                    downloadLink.target = '_blank'
                    downloadLink.download = response.headers[
                        'content-disposition'
                    ]
                        .split('=')[1]
                        .split(';')[0]
                    downloadLink.click()
                }
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setIsDownloading(false) // Set isDownloading to false at the end
            })
    }
    if (
        IsValidDataCutSelection(
            sectorValue,
            verticalValue,
            ownershipValue,
            headcountValue,
            startupValue,
            headcountOptions
        ) ||
        IsValidOtherOptions(
            salaryFreeze,
            showNonReportableDataValue,
            yearValue
        ) ||
        (reportValue &&
            REPORT_OPTIONS_PAY_ZONED_COUNTRIES_ALL[survey]
                .reduce((acc, val) => acc.concat(val.options), [])
                .find((c) => c.value === reportValue) === undefined) ||
        (countryValue &&
            GROUPED_COUNTRY_OPTIONS[survey]
                .reduce((acc, val) => acc.concat(val.options), [])
                .find((c) => c.value === countryValue) === undefined)
    ) {
        return (
            <div>
                <PageNotFound />
            </div>
        )
    } else {
        return (
            <Container fluid>
                <div>
                    <Row>
                        <Col
                            sm={12}
                            lg={3}
                            className="pane printHidden"
                            style={
                                !reportValue || isReportLoading
                                    ? { height: '100vh' }
                                    : {}
                            }
                        >
                            <ReactSelect
                                label={TEXT_COUNTRY_SELECTION}
                                placeholder={TEXT_SELECT_PLACEHOLDER}
                                className="react-select form-control p-0"
                                options={GROUPED_COUNTRY_OPTIONS[survey]}
                                formatGroupLabel={formatGroupLabel}
                                onChange={handleCountryChange}
                                value={
                                    countryValue
                                        ? GROUPED_COUNTRY_OPTIONS[survey]
                                              .reduce(
                                                  (acc, val) =>
                                                      acc.concat(val.options),
                                                  []
                                              )
                                              .find(
                                                  (c) =>
                                                      c.value === countryValue
                                              )
                                        : TEXT_SELECT_PLACEHOLDER
                                }
                                closeMenuOnSelect={true}
                            />

                            <ReactSelect
                                label={TEXT_REPORT_SELECTION}
                                className="react-select form-control p-0"
                                placeholder={
                                    isReportLoading
                                        ? REPORT_LOADING_MSG
                                        : TEXT_SELECT_PLACEHOLDER
                                }
                                options={reportDropDownOptionList}
                                formatGroupLabel={formatGroupLabel}
                                onChange={handleReportOptionChange}
                                isDisabled={!countryValue}
                                value={
                                    isReportLoading === false
                                        ? reportValue
                                            ? REPORT_OPTIONS_PAY_ZONED_COUNTRIES_ALL[
                                                  survey
                                              ]
                                                  .reduce(
                                                      (acc, val) =>
                                                          acc.concat(
                                                              val.options
                                                          ),
                                                      []
                                                  )
                                                  .find(
                                                      (c) =>
                                                          c.value ===
                                                          reportValue
                                                  )
                                            : TEXT_SELECT_PLACEHOLDER
                                        : TEXT_SELECT_PLACEHOLDER
                                }
                                isLoading={isReportLoading}
                                closeMenuOnSelect={true}
                            />
                            <p className="report-seperator">
                                {TEXT_YEAR_REPORT_SELECTION}
                            </p>

                            <div className="freeze-and-year-filters">
                                <RadioButton
                                    label={
                                        ' ' +
                                        CURRENT_YEAR_LIST[survey] +
                                        ' Actual Values'
                                    }
                                    value={yearValue === CURRENT_YEAR_TEXT}
                                    onChange={handleActualChange}
                                    disabled={!reportValue}
                                />
                                <tr></tr>
                                <RadioButton
                                    label={
                                        ' ' +
                                        UPCOMING_YEAR_LIST[survey] +
                                        ' Estimated / Projected Values'
                                    }
                                    value={
                                        yearValue === UPCOMING_YEAR_TEXT ||
                                        !yearValue
                                    }
                                    onChange={handleProjectedChange}
                                    disabled={!reportValue}
                                />
                            </div>

                            <div
                                className="datacuts-filters"
                                style={
                                    reportValue
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <p className="report-seperator">
                                    {TEXT_DATA_CUTS_SELECTION}
                                </p>
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_INDUSTRY_SECTOR}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={SECTOR_GROUPS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleSectorChange}
                                    isDisabled={
                                        reportValue ===
                                            REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_SECTOR ||
                                        (PRELIM_SURVEY_LIST.includes(survey)
                                            ? verticalValue ||
                                              ownershipValue ||
                                              headcountValue ||
                                              startupValue
                                            : false)
                                    }
                                    value={
                                        sectorValue
                                            ? SECTOR_GROUPS.reduce(
                                                  (acc, val) =>
                                                      acc.concat(val.options),
                                                  []
                                              ).find(
                                                  (c) => c.value === sectorValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_INDUSTRY_VERTICAL}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={VERTICALS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleVerticalChange}
                                    isDisabled={
                                        reportValue ===
                                            REPORT_BASE_SALARY_INCREASE_BY_INDUSTRY_VERTICAL ||
                                        (PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              ownershipValue ||
                                              headcountValue ||
                                              startupValue
                                            : false)
                                    }
                                    value={
                                        verticalValue
                                            ? VERTICALS.find(
                                                  (c) =>
                                                      c.value === verticalValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_OWNERSHIP}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={OWNERSHIP_GROUPS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleOwnershipChange}
                                    isDisabled={
                                        reportValue ===
                                            REPORT_BASE_SALARY_INCREASE_BY_OWNERSHIP ||
                                        (PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              headcountValue ||
                                              startupValue
                                            : false)
                                    }
                                    value={
                                        ownershipValue
                                            ? OWNERSHIP_GROUPS.reduce(
                                                  (acc, val) =>
                                                      acc.concat(val.options),
                                                  []
                                              ).find(
                                                  (c) =>
                                                      c.value === ownershipValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_HEADCOUNT}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={headcountOptions}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleHeadcountChange}
                                    isDisabled={
                                        reportValue ===
                                            REPORT_BASE_SALARY_INCREASE_BY_HEADCOUNT ||
                                        (PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              ownershipValue ||
                                              startupValue
                                            : false)
                                    }
                                    value={
                                        headcountValue
                                            ? headcountOptions.find(
                                                  (c) =>
                                                      c.value === headcountValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_STARTUP}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={STARTUP_OPTIONS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleStartupChange}
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              ownershipValue ||
                                              headcountValue
                                            : false
                                    }
                                    value={
                                        startupValue
                                            ? STARTUP_OPTIONS.find(
                                                  (c) =>
                                                      c.value === startupValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                            </div>
                            <p
                                className="report-seperator"
                                style={
                                    reportValue
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                {TEXT_OTHER_OPTIONS}
                            </p>
                            <div
                                className="freeze-and-year-filters"
                                style={
                                    reportValue
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <Checkbox
                                    label={SALARY_FREEZE_BUTTON_TEXT}
                                    value={
                                        !salaryFreeze ||
                                        salaryFreeze === BOOLEAN_TEXT_TRUE
                                    }
                                    onChange={handleSalaryFreezeChange}
                                    disabled={!reportValue}
                                />
                                <tr></tr>
                                <Checkbox
                                    label={UNAVAIL_DATA_BUTTON_TEXT}
                                    value={
                                        showNonReportableDataValue ===
                                            BOOLEAN_TEXT_TRUE ||
                                        !showNonReportableDataValue
                                    }
                                    onChange={handleShowNonReportableData}
                                    disabled={!reportValue}
                                />
                            </div>
                        </Col>
                        <Col
                            sm={12}
                            lg={9}
                            className="table-area"
                            style={
                                isReportLoading === true
                                    ? { display: 'none' }
                                    : reportValue
                                    ? { display: 'block' }
                                    : { display: 'none' }
                            }
                        >
                            <div className="printVisible">
                                <div className="logo-print-view">
                                    <img
                                        src="https://ww2.culpepper.com/images/logos/CulpepperLogoNoTagline.svg"
                                        alt=""
                                        width="238.19"
                                    ></img>
                                </div>
                            </div>
                            <p className="table-title">
                                {yearValue === UPCOMING_YEAR_TEXT || !yearValue
                                    ? UPCOMING_YEAR_LIST[survey] +
                                      ' ' +
                                      UPCOMING_YEAR_TEXT
                                    : CURRENT_YEAR_LIST[survey] +
                                      ' ' +
                                      CURRENT_YEAR_TEXT}{' '}
                                {reportValue}
                            </p>
                            <hr style={{ width: '100%' }} />{' '}
                            {EXPORT_OPTION_AVALIBLE_SURVEY_LIST.includes(
                                survey
                            ) ? (
                                <div>
                                    <img
                                        disabled={isDownloading}
                                        className="export-file-images"
                                        alt="Excel"
                                        src="https://ww3.culpepper.com/l/137751/2016-02-02/23qp6/137751/2041/excel.gif"
                                        onClick={(e) => {
                                            if (isDownloading) {
                                                e.preventDefault()
                                                return
                                            }
                                            handleExcelDownload()
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            filter: isDownloading
                                                ? 'grayscale(100%)'
                                                : 'none',
                                        }} // Show pointer cursor to indicate clickability and apply grayscale filter if image is disabled
                                    ></img>
                                </div>
                            ) : null}
                            <div className="container container-margin">
                                <p className="practice-data-question">
                                    {reportQuestion}
                                </p>
                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        <span className="datacut-type">
                                            {COUNTRY_SELECTED_TEXT}:
                                        </span>
                                    </div>
                                    <div className="col-auto text-left">
                                        {countryValue}
                                    </div>
                                </div>

                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            sectorValue,
                                            TEXT_INDUSTRY_SECTOR
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            sectorValue
                                        )}
                                    </div>
                                </div>
                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            verticalValue,
                                            TEXT_INDUSTRY_VERTICAL
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            verticalValue
                                        )}
                                    </div>
                                </div>

                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            ownershipValue,
                                            TEXT_OWNERSHIP
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            ownershipValue
                                        )}
                                    </div>
                                </div>

                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            headcountValue,
                                            TEXT_HEADCOUNT
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            headcountValue
                                        )}
                                    </div>
                                </div>

                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        {getDataCutSelectionTextTitle(
                                            startupValue,
                                            TEXT_STARTUP
                                        )}
                                    </div>
                                    <div className="col-auto text-left">
                                        {getDataCutSelectionTextName(
                                            startupValue
                                        )}
                                    </div>
                                </div>
                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-xl-2 text-left">
                                        <span className="datacut-type">
                                            {SALARY_FREEZE_BUTTON_TEXT}:
                                        </span>
                                    </div>
                                    <div className="col-auto text-left">
                                        {salaryFreeze === BOOLEAN_TEXT_TRUE ||
                                        !salaryFreeze ? (
                                            <span>Yes</span>
                                        ) : (
                                            <span>No</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {shouldDisplayErrorText() ? (
                                <div className="data-availability">
                                    {TEXT_NO_AVAIL_DATA}
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div className="pop-up-div printHidden">
                                <Popup
                                    trigger={
                                        <Button
                                            variant="light"
                                            className="column-selection-button"
                                        >
                                            {
                                                REPORT_COLUMN_SELECTION_BUTTON_TEXT
                                            }
                                        </Button>
                                    }
                                    position="bottom center"
                                >
                                    <div className="pop-up-menu">
                                        <CheckboxPercentiles
                                            className="checkbox-container"
                                            type="checkbox"
                                            name="selectAll"
                                            id="selectAll"
                                            handleClick={handleSelectAll}
                                            isChecked={isCheckAll}
                                        />
                                        <label className="percentile-labels">
                                            Include All
                                        </label>
                                        <hr></hr>
                                        {catalog}
                                    </div>
                                </Popup>
                            </div>
                            <div className="tableFixedHead-for-print printVisible">
                                {DisplayData}
                            </div>
                            <div className="tableFixHead printHidden">
                                {DisplayData}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default Country
