import React from 'react'
import { Link, useParams } from 'react-router-dom'
import {
    PRELIM_SURVEY_LIST_WITHOUT_START_DATE,
    SURVEY_YEAR_NAME_LIST,
    SURVEY_ORG_NUMBER_LIST,
    SURVEY_PUBLISH_DATE_LIST,
    SURVEY_UUID_LIST,
    PRELIM_SURVEY_LIST,
    PRELIM_HOME_PAGE_REGION_SHEET_NAME,
    HOME_PAGE_PRELIM_ANNOTATION_TEXT,
    SURVEY_OLDEST_SUBMISSION_DATE,
    SURVEY_MOST_RECENT_SUBMISSION_DATE,
    SURVEY_YEAR_FOR_LAST_AVAILABLE_HISTORICAL_YEAR,
    SURVEY_RESULTS_PDF_FILE_LIST,
    REGION_DEFAULT_SELECTION,
    COUNTRY_DEFAULT_SELECTION,
    PRACTICE_DEFAULT_SELECTION,
    HISTORICAL_DEFAULT_SELECTION,
    FIRST_HISTORICAL_YEAR,
    SURVEY_TITLE_TEXT_NO_CULPEPPER_PRELIM,
    SURVEY_TITLE_TEXT_NO_CULPEPPER_FINAL,
    SURVEY_AVAIL_COUNTRY_NUMBER_LIST,
    SURVEY_RESULTS_EXCEL_FILE_LIST,
    SURVEY_RESULT_PDF_FILE_TEXT_TITLE,
    SURVEY_RESULT_EXCEL_FILE_TEXT_TITLE,
    NEXT_SURVEY_NAME_LIST,
    NEXT_SURVEY_OPEN_DATES,
    CURRENT_FINAL_RESULTS_SURVEY_LIST,
    FINAL_RESULTS_AVAILABLE_DATE,
    SURVEY_QUESTIONNAIRE_FILE_LIST,
    SURVEY_QUESTIONNAIRE_PDF_FILE_TEXT_TITLE,
    DEFINITIONS_DEFAULT_SELECTION,
    SURVEY_YEAR_2023_2024,
    SURVEY_YEAR_2023_2024_PRELIM,
    SURVEY_RESULT_WORD_FILE_TEXT_TITLE,
    SURVEY_RESULTS_WORD_FILE_LIST,
    SBS_DEV_PAGE_ADDRESS,
    SBS_LOCAL_DEV_PAGE_ADDRESS,
    SURVEY_YEAR_2024_PRELIM,
    SURVEY_YEAR_2024,
    SURVEY_YEAR_2024_2025_PRELIM,
    SURVEY_YEAR_2024_2025,
    SURVEY_YEAR_2023_PRELIM,
    SURVEY_YEAR_2023,
    EXCEL_TABLE_AVAILABLE_SURVEYS,
    NEXT_SURVEY_PARTICIPATION_LINKS,
} from '../constants/constants'
import { REACT_APP_PAGE_ADDRESS } from '../config'

function Home() {
    const params = useParams()
    const surveyUUID = params.survey
    const survey = SURVEY_UUID_LIST[surveyUUID]

    const regionTopics = (
        <div>
            <p className="text-regular-indented">
                <ul className="nonlist-with-link">
                    <Link
                        className="link-padding"
                        to={'/' + params.survey + REGION_DEFAULT_SELECTION}
                    >
                        Global Overview of Base Salary Increases
                    </Link>
                </ul>
            </p>
        </div>
    )

    const countryTopics = (
        <div>
            <p className="text-regular-indented">
                {PRELIM_SURVEY_LIST.includes(survey) ? (
                    <span>Country Breakouts will include:</span>
                ) : (
                    <ul className="nonlist-with-link">
                        <Link
                            className="link-padding"
                            to={'/' + params.survey + COUNTRY_DEFAULT_SELECTION}
                        >
                            Country Breakout Reports
                        </Link>
                    </ul>
                )}
            </p>
            {[SURVEY_YEAR_2023_2024, SURVEY_YEAR_2023_2024_PRELIM].includes(
                survey
            ) ? (
                <ul className="list-with-link">
                    <li className="individual-link">
                        Salary Increases by Industry Sector and Vertical
                    </li>
                    <li className="individual-link">
                        Salary Increases by Ownership
                    </li>
                    <li className="individual-link">
                        Salary Increases by Headcount
                    </li>
                    <li className="individual-link">
                        Salary Increases by Geographic Locale
                    </li>
                    <li className="individual-link">
                        Salary Increases by Geographic Pay Zone
                    </li>
                    <li className="individual-link">
                        Salary Increases by Job Function
                    </li>
                    <li className="individual-link">
                        Salary Increases by Job Level
                    </li>
                    <li className="individual-link">
                        Salary Increases by Performance
                    </li>
                    <li className="individual-link">
                        Salary Structure Adjustments
                    </li>
                    <li className="individual-link">
                        Promotional Base Pay Increases
                    </li>
                </ul>
            ) : [SURVEY_YEAR_2024_PRELIM, SURVEY_YEAR_2024].includes(survey) ? (
                <ul className="list-with-link">
                    <li className="individual-link">
                        Salary Increases by Industry Sector and Vertical
                    </li>
                    <li className="individual-link">
                        Salary Increases by Ownership
                    </li>
                    <li className="individual-link">
                        Salary Increases by Headcount
                    </li>
                    <li className="individual-link">
                        Salary Increases by Geographic Locale
                    </li>
                    <li className="individual-link">
                        Salary Increases by Geographic Pay Zone
                    </li>
                    <li className="individual-link">
                        Salary Increases by Job Level
                    </li>
                    <li className="individual-link">
                        Salary Increases by Job Function
                    </li>
                    <li className="individual-link">
                        Salary Increases by Performance
                    </li>
                    <li className="individual-link">
                        Salary Structure Adjustments
                    </li>
                    <li className="individual-link">
                        Promotional Base Pay Increases
                    </li>
                    <li className="individual-link">
                        Short-Term Cash Incentive Budgets
                    </li>
                </ul>
            ) : [SURVEY_YEAR_2024_2025_PRELIM, SURVEY_YEAR_2024_2025].includes(
                  survey
              ) ? (
                <ul className="list-with-link">
                    <li className="individual-link">
                        Salary Increases by Industry Sector and Vertical
                    </li>
                    <li className="individual-link">
                        Salary Increases by Ownership
                    </li>
                    <li className="individual-link">
                        Salary Increases by Headcount
                    </li>
                    <li className="individual-link">
                        Salary Increases by Geographic Locale
                    </li>
                    <li className="individual-link">
                        Salary Increases by Geographic Pay Zone
                    </li>
                    <li className="individual-link">
                        Salary Increases by Job Level and Function
                    </li>
                    <li className="individual-link">
                        Salary Increases by Performance
                    </li>
                    <li className="individual-link">
                        Salary Increases by Position-in-Range
                    </li>
                    <li className="individual-link">
                        Salary Increases by Compa-Ratio
                    </li>
                    <li className="individual-link">
                        Salary Structure Adjustments
                    </li>
                    <li className="individual-link">
                        Promotional Base Pay Increases
                    </li>
                    <li className="individual-link">
                        Short-Term Cash Incentive Budgets
                    </li>
                </ul>
            ) : (
                <ul className="list-with-link">
                    <li className="individual-link">
                        Salary Increases by Industry Sector and Vertical
                    </li>
                    <li className="individual-link">
                        Salary Increases by Ownership
                    </li>
                    <li className="individual-link">
                        Salary Increases by Headcount
                    </li>
                    <li className="individual-link">
                        Salary Increases by Geographic Locale
                    </li>
                    <li className="individual-link">
                        Salary Increases by Geographic Pay Zone
                    </li>
                    <li className="individual-link">
                        Salary Increases by Job Level and Function
                    </li>
                    <li className="individual-link">
                        Salary Increases by Performance
                    </li>
                    <li className="individual-link">
                        Salary Structure Adjustments
                    </li>
                    <li className="individual-link">
                        Promotional Base Pay Increases
                    </li>
                    <li className="individual-link">
                        Short-Term Cash Incentive Budgets
                    </li>
                </ul>
            )}
        </div>
    )
    const practiceTopics = (
        <div>
            <p className="text-regular-indented">
                {PRELIM_SURVEY_LIST.includes(survey) ? (
                    <span>Practices Reports will cover:</span>
                ) : (
                    <ul className="nonlist-with-link">
                        <Link
                            className="link-padding"
                            to={
                                '/' +
                                params.survey +
                                PRACTICE_DEFAULT_SELECTION[survey]
                            }
                        >
                            Practices Reports
                        </Link>
                    </ul>
                )}
            </p>
            {[SURVEY_YEAR_2024_2025_PRELIM, SURVEY_YEAR_2024_2025].includes(
                survey
            ) ? (
                <ul className="list-with-link">
                    <li className="individual-link">
                        Frequency of Base Salary Reviews
                    </li>
                    <li className="individual-link">
                        Factors Used to Determine Base Pay Increases
                    </li>
                    <li className="individual-link">
                        Prevalence of Salary Structures
                    </li>
                    <li className="individual-link">
                        Frequency of Salary Structure Adjustments
                    </li>
                    <li className="individual-link">
                        Prevalence of Short-Term Cash Incentives
                    </li>
                    <li className="individual-link">
                        Types of Short-Term Cash Incentives
                    </li>
                    <li className="individual-link">
                        Prevalence of Long-Term Incentives
                    </li>
                    <li className="individual-link">
                        Types of Long-Term Incentives
                    </li>
                </ul>
            ) : [
                  SURVEY_YEAR_2023_2024,
                  SURVEY_YEAR_2023_2024_PRELIM,
                  SURVEY_YEAR_2024_PRELIM,
                  SURVEY_YEAR_2024,
              ].includes(survey) ? (
                <ul className="list-with-link">
                    <li className="individual-link">
                        Frequency of Base Salary Reviews
                    </li>
                    <li className="individual-link">
                        Prevalence of Salary Structures
                    </li>
                    <li className="individual-link">
                        Frequency of Salary Structure Adjustments
                    </li>
                    <li className="individual-link">
                        Prevalence of Short-Term Cash Incentives
                    </li>
                    <li className="individual-link">
                        Types of Short-Term Cash Incentives
                    </li>
                    <li className="individual-link">
                        Prevalence of Long-Term Incentives
                    </li>
                    <li className="individual-link">
                        Types of Long-Term Incentives
                    </li>
                </ul>
            ) : [SURVEY_YEAR_2023_PRELIM, SURVEY_YEAR_2023].includes(survey) ? (
                <ul className="list-with-link">
                    <li className="individual-link">Base Pay Philosophy</li>
                    <li className="individual-link">
                        Frequency of Base Pay Increases
                    </li>
                    <li className="individual-link">
                        Prevalence of Salary Structures
                    </li>
                    <li className="individual-link">
                        Frequency of Salary Structure Adjustments
                    </li>
                    <li className="individual-link">
                        Salary Budgets by Geographic Location
                    </li>
                    <li className="individual-link">
                        Types of Base Pay Increases
                    </li>
                    <li className="individual-link">
                        Impact of Inflation on the Timing of Salary Increases
                    </li>
                    <li className="individual-link">
                        Other Compensation Plans & Practices
                    </li>
                </ul>
            ) : null}
        </div>
    )
    const participants = (
        <div>
            {' '}
            <p className="text-regular-indented">
                <ul className="nonlist-with-link">
                    <Link
                        className="link-padding"
                        to={'/' + params.survey + '/participants'}
                    >
                        List of Participants
                    </Link>
                </ul>
            </p>
        </div>
    )
    const historicalData = (
        <div>
            <p className="text-regular-indented">
                <ul className="nonlist-with-link">
                    <Link
                        className="link-padding"
                        to={'/' + params.survey + HISTORICAL_DEFAULT_SELECTION}
                    >
                        Historical Base Salary Increases by Year (
                        {FIRST_HISTORICAL_YEAR} - {''}
                        {SURVEY_YEAR_FOR_LAST_AVAILABLE_HISTORICAL_YEAR[survey]}
                        )
                    </Link>
                </ul>
            </p>
        </div>
    )

    const resultsFile = (
        <div>
            <p className="text-regular-indented">
                {[
                    SBS_LOCAL_DEV_PAGE_ADDRESS.replace(/\/$/, ''),
                    SBS_DEV_PAGE_ADDRESS.replace(/\/$/, ''),
                ].includes(REACT_APP_PAGE_ADDRESS.replace(/\/$/, '')) ? (
                    <ul className="nonlist-with-link">
                        <a
                            className="link-padding"
                            href={require('../files/' +
                                SURVEY_RESULTS_WORD_FILE_LIST[survey])}
                            download={SURVEY_RESULTS_WORD_FILE_LIST[survey]}
                        >
                            {SURVEY_RESULT_WORD_FILE_TEXT_TITLE}{' '}
                        </a>
                        <img
                            className="survey-file-images"
                            alt="WORD"
                            border="0"
                            src={require('../imgs/docx_icon.svg.png')}
                        ></img>
                    </ul>
                ) : null}

                <ul className="nonlist-with-link">
                    <a
                        className="link-padding"
                        href={require('../files/' +
                            SURVEY_RESULTS_PDF_FILE_LIST[survey])}
                        download={SURVEY_RESULTS_PDF_FILE_LIST[survey]}
                    >
                        {SURVEY_RESULT_PDF_FILE_TEXT_TITLE}{' '}
                    </a>
                    <img
                        className="survey-file-images"
                        alt="PDF"
                        border="0"
                        src="https://ww3.culpepper.com/l/137751/2016-01-28/21br6/137751/1645/pdf.gif"
                    ></img>
                </ul>
                {EXCEL_TABLE_AVAILABLE_SURVEYS.includes(survey) ? (
                    <div>
                        <ul className="nonlist-with-link">
                            <a
                                className="link-padding"
                                href={require('../files/' +
                                    SURVEY_RESULTS_EXCEL_FILE_LIST[survey])}
                                download={
                                    SURVEY_RESULTS_EXCEL_FILE_LIST[survey]
                                }
                            >
                                {SURVEY_RESULT_EXCEL_FILE_TEXT_TITLE}{' '}
                            </a>
                            <img
                                className="survey-file-images"
                                alt="Excel"
                                border="0"
                                src="https://ww3.culpepper.com/l/137751/2016-02-02/23qp6/137751/2041/excel.gif"
                            ></img>
                        </ul>
                    </div>
                ) : null}
            </p>
        </div>
    )

    const questionnaireFile = (
        <div>
            <p className="text-regular-indented">
                <ul className="nonlist-with-link">
                    <a
                        className="link-padding"
                        href={require('../files/' +
                            SURVEY_QUESTIONNAIRE_FILE_LIST[survey])}
                        download={SURVEY_QUESTIONNAIRE_FILE_LIST[survey]}
                    >
                        {SURVEY_QUESTIONNAIRE_PDF_FILE_TEXT_TITLE}{' '}
                    </a>
                    <img
                        className="survey-file-images"
                        alt="PDF"
                        border="0"
                        src="https://ww3.culpepper.com/l/137751/2016-01-28/21br6/137751/1645/pdf.gif"
                    ></img>
                </ul>
            </p>
        </div>
    )

    const definitions = (
        <div>
            <p className="text-regular-indented">
                <ul className="nonlist-with-link">
                    <Link
                        className="link-padding"
                        to={'/' + params.survey + DEFINITIONS_DEFAULT_SELECTION}
                    >
                        Definitions
                    </Link>
                </ul>
            </p>
        </div>
    )

    const OpenedNextSurvey = (
        <div>
            <p className="text-bold">
                <span>
                    {NEXT_SURVEY_NAME_LIST[survey]} Salary Budget Survey
                </span>
            </p>
            <p className="text-regular">
                <span className="text-regular-only">
                    The {NEXT_SURVEY_NAME_LIST[survey]} Salary Budget &amp;
                    Compensation Planning Survey is open for participation.
                </span>
            </p>
            <p className="text-regular">
                <a
                    href={NEXT_SURVEY_PARTICIPATION_LINKS[survey]}
                    style={{ textDecoration: 'underline' }}
                >
                    Participate and receive results.
                </a>
            </p>
        </div>
    )

    const UnopenedNextSurvey = (
        <div>
            <p className="text-bold">
                <span>
                    {NEXT_SURVEY_NAME_LIST[survey]} Salary Budget Survey
                </span>
            </p>
            <p className="text-regular">
                <span className="text-regular-only">
                    The {NEXT_SURVEY_NAME_LIST[survey]} Salary Budget &amp;
                    Compensation Planning Survey will be open for participation
                    in {NEXT_SURVEY_OPEN_DATES[survey]}.
                </span>
            </p>
        </div>
    )
    return (
        <div className="d-flex justify-content-between ">
            <div className="flex-fill empty-space"></div>
            <div className="home-page-main">
                <div className="text-section-title">
                    {PRELIM_SURVEY_LIST.includes(survey) ? (
                        <h4>
                            {SURVEY_YEAR_NAME_LIST[survey]}{' '}
                            {SURVEY_TITLE_TEXT_NO_CULPEPPER_PRELIM}
                        </h4>
                    ) : (
                        <h4>
                            {SURVEY_YEAR_NAME_LIST[survey]}{' '}
                            {SURVEY_TITLE_TEXT_NO_CULPEPPER_FINAL}
                        </h4>
                    )}
                </div>
                <div className="text-section">
                    {/*show text different for current survey results based on if there is a list survey and if it is a prelim results*/}
                    <p className="text-bold">
                        {PRELIM_SURVEY_LIST.includes(survey) ? (
                            <span>Preliminary Survey Results</span>
                        ) : (
                            <span></span>
                        )}{' '}
                        {PRELIM_SURVEY_LIST.includes(survey) ? (
                            <em className="home-page-publish-dates">
                                (published {SURVEY_PUBLISH_DATE_LIST[survey]})
                            </em>
                        ) : (
                            <em></em>
                        )}
                    </p>
                    {/*show text differnet based on if it is a prelim results or final results*/}
                    {PRELIM_SURVEY_LIST.includes(survey) ? (
                        <p className="text-regular-indented">
                            <p>
                                {HOME_PAGE_PRELIM_ANNOTATION_TEXT} include data
                                collected from{' '}
                                {SURVEY_ORG_NUMBER_LIST[
                                    survey
                                ].toLocaleString()}{' '}
                                companies{' '}
                                {PRELIM_SURVEY_LIST_WITHOUT_START_DATE.includes(
                                    survey
                                )
                                    ? `through ${SURVEY_MOST_RECENT_SUBMISSION_DATE[survey]}.`
                                    : `from ${SURVEY_OLDEST_SUBMISSION_DATE[survey]} to ${SURVEY_MOST_RECENT_SUBMISSION_DATE[survey]}.`}
                            </p>
                        </p>
                    ) : (
                        <p className="text-regular-indented-home-page">
                            <p>
                                <span className="new-line">
                                    <span className="text-bold-only">
                                        Publication Date:{' '}
                                    </span>
                                    <span className="text-regular-only">
                                        {SURVEY_PUBLISH_DATE_LIST[survey]}{' '}
                                    </span>
                                </span>
                                <span className="new-line">
                                    <span className="text-bold-only">
                                        Survey Dates:{' '}
                                    </span>
                                    <span className="text-regular-only">
                                        {SURVEY_OLDEST_SUBMISSION_DATE[survey]}{' '}
                                        through{' '}
                                        {
                                            SURVEY_MOST_RECENT_SUBMISSION_DATE[
                                                survey
                                            ]
                                        }
                                    </span>
                                </span>

                                <span className="new-line">
                                    <span className="text-bold-only">
                                        Participants:{' '}
                                    </span>
                                    <span className="text-regular-only">
                                        Data was collected from{' '}
                                        {SURVEY_ORG_NUMBER_LIST[
                                            survey
                                        ].toLocaleString()}{' '}
                                        participating organizations reporting
                                        salary budget data for employees across{' '}
                                        {
                                            SURVEY_AVAIL_COUNTRY_NUMBER_LIST[
                                                survey
                                            ]
                                        }{' '}
                                        countries and territories.
                                    </span>
                                </span>
                            </p>
                        </p>
                    )}

                    {/*if it is prelim results, only shows the global increases link*/}
                    {PRELIM_SURVEY_LIST.includes(survey) ? (
                        <div>
                            <div>
                                <ul className="prelim-nonlist-with-link">
                                    <Link
                                        className="link-padding"
                                        to={
                                            '/' +
                                            params.survey +
                                            // '/region?region=Global'
                                            REGION_DEFAULT_SELECTION
                                        }
                                    >
                                        {PRELIM_HOME_PAGE_REGION_SHEET_NAME}
                                    </Link>
                                </ul>
                            </div>
                            <div>
                                <p className="text-bold">
                                    Final Survey Results{' '}
                                    <em className="home-page-publish-dates">
                                        (available by{' '}
                                        {FINAL_RESULTS_AVAILABLE_DATE[survey]})
                                    </em>
                                </p>
                                <p className="text-regular-indented">
                                    <p>
                                        Final comprehensive results will include
                                        new and refreshed data from
                                        participating companies.
                                    </p>
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {resultsFile}
                            {regionTopics}
                        </div>
                    )}

                    {countryTopics}
                    {practiceTopics}
                    {PRELIM_SURVEY_LIST.includes(survey) ? (
                        <div className="prelim-site-space-holder"></div>
                    ) : (
                        <div></div>
                    )}
                    {PRELIM_SURVEY_LIST.includes(survey) ? (
                        <div></div>
                    ) : (
                        <div>
                            {historicalData}

                            {questionnaireFile}
                            {participants}
                            {definitions}

                            {CURRENT_FINAL_RESULTS_SURVEY_LIST.includes(
                                survey
                            ) ? (
                                <div className="next-survey-section">
                                    <hr></hr>
                                    <div className="next-survey-div">
                                        {OpenedNextSurvey}
                                        {/* {UnopenedNextSurvey} */}
                                    </div>
                                </div>
                            ) : (
                                <div className="next-survey-div"></div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="flex-fill empty-space "></div>
        </div>
    )
}

export default Home
