export const SURVEY_UUID_2022_2023 = '5670c78db9fb'
export const SURVEY_UUID_2023_PRELIM = 'b636060b60ac'
export const SURVEY_UUID_2023 = 'fd15e3c4a64d'
export const SURVEY_UUID_2023_2024_PRELIM = '8a875aa2092e'
export const SURVEY_UUID_2023_2024 = '62d7afa6129f'
export const SURVEY_UUID_2024_PRELIM = '2d1e3e5f63ag'
export const SURVEY_UUID_2024 = '4s2a1b3e5f6t7h'
export const SURVEY_UUID_2024_2025_PRELIM = '0d635a2c524i'
export const SURVEY_UUID_2024_2025 = '1a746b4d839j'

export const SURVEY_YEAR_2022_2023 = '2022_2023'
export const SURVEY_YEAR_2023_PRELIM = '2023_prelim'
export const SURVEY_YEAR_2023 = '2023'
export const SURVEY_YEAR_2023_2024_PRELIM = '2023_2024_prelim'
export const SURVEY_YEAR_2023_2024 = '2023_2024'
export const SURVEY_YEAR_2024_PRELIM = '2024_prelim'
export const SURVEY_YEAR_2024 = '2024'
export const SURVEY_YEAR_2024_2025_PRELIM = '2024_2025_prelim'
export const SURVEY_YEAR_2024_2025 = '2024_2025'

export const PRELIM_SURVEY_LIST = [
    SURVEY_YEAR_2023_PRELIM,
    SURVEY_YEAR_2023_2024_PRELIM,
    SURVEY_YEAR_2024_PRELIM,
    SURVEY_YEAR_2024_2025_PRELIM,
]

export const PRELIM_SURVEY_LIST_WITHOUT_START_DATE = [SURVEY_YEAR_2024_PRELIM]

export const DISABLED_ON_PROD_PRELIM_SURVEY_LIST = [
    SURVEY_YEAR_2023_PRELIM,
    SURVEY_YEAR_2023_2024_PRELIM,
    SURVEY_YEAR_2024_PRELIM,
    SURVEY_YEAR_2024_2025_PRELIM,
]
export const UPCOMING_ON_PROD_PRELIM_SURVEY_LIST = []
export const UPCOMNING_ON_PROD_FINAL_SURVEY_LIST = []
export const EXPORT_OPTION_AVALIBLE_SURVEY_LIST = [
    SURVEY_YEAR_2023_2024,
    SURVEY_YEAR_2024_PRELIM,
    SURVEY_YEAR_2024,
    SURVEY_YEAR_2024_2025_PRELIM,
    SURVEY_YEAR_2024_2025,
]

export const SURVEY_UUID_LIST = {}
SURVEY_UUID_LIST[SURVEY_UUID_2022_2023] = SURVEY_YEAR_2022_2023
SURVEY_UUID_LIST[SURVEY_UUID_2023_PRELIM] = SURVEY_YEAR_2023_PRELIM
SURVEY_UUID_LIST[SURVEY_UUID_2023] = SURVEY_YEAR_2023
SURVEY_UUID_LIST[SURVEY_UUID_2023_2024_PRELIM] = SURVEY_YEAR_2023_2024_PRELIM
SURVEY_UUID_LIST[SURVEY_UUID_2023_2024] = SURVEY_YEAR_2023_2024
SURVEY_UUID_LIST[SURVEY_UUID_2024_PRELIM] = SURVEY_YEAR_2024_PRELIM
SURVEY_UUID_LIST[SURVEY_UUID_2024] = SURVEY_YEAR_2024
SURVEY_UUID_LIST[SURVEY_UUID_2024_2025_PRELIM] = SURVEY_YEAR_2024_2025_PRELIM
SURVEY_UUID_LIST[SURVEY_UUID_2024_2025] = SURVEY_YEAR_2024_2025

export const SURVEY_PREVIOUS_UUID = {}
SURVEY_PREVIOUS_UUID[SURVEY_UUID_2022_2023] = undefined
SURVEY_PREVIOUS_UUID[SURVEY_UUID_2023_PRELIM] = SURVEY_UUID_2022_2023
SURVEY_PREVIOUS_UUID[SURVEY_UUID_2023] = SURVEY_UUID_2022_2023
SURVEY_PREVIOUS_UUID[SURVEY_UUID_2023_2024] = SURVEY_UUID_2023
SURVEY_PREVIOUS_UUID[SURVEY_UUID_2024] = SURVEY_UUID_2023_2024

export const SURVEY_YEAR_NAME_LIST = {
    [SURVEY_YEAR_2022_2023]: '2022-2023',
    [SURVEY_YEAR_2023_PRELIM]: '2023',
    [SURVEY_YEAR_2023]: '2023',
    [SURVEY_YEAR_2023_2024_PRELIM]: '2023-2024',
    [SURVEY_YEAR_2023_2024]: '2023-2024',
    [SURVEY_YEAR_2024_PRELIM]: '2024',
    [SURVEY_YEAR_2024]: '2024',
    [SURVEY_YEAR_2024_2025_PRELIM]: '2024-2025',
    [SURVEY_YEAR_2024_2025]: '2024-2025',
}

export const SURVEY_REDIRECT_LINK_LIST = {
    [SURVEY_YEAR_2023_PRELIM]: 'https://ww3.culpepper.com/SBS/2023/pr/',
    [SURVEY_YEAR_2023_2024_PRELIM]:
        'https://ww3.culpepper.com/SBS/20232024/pr/',
    [SURVEY_YEAR_2024_PRELIM]: 'https://ww3.culpepper.com/SBS/2024/pr/',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'https://ww3.culpepper.com/SBS/2425/pr/',
}

export const CURRENT_FINAL_RESULTS_SURVEY_LIST = [SURVEY_YEAR_2024_2025]
export const NEXT_SURVEY_NAME_LIST = {
    [SURVEY_YEAR_2023]: '2023-2024',
    [SURVEY_YEAR_2023_2024]: '2024',
    [SURVEY_YEAR_2024]: '2024-2025',
    [SURVEY_YEAR_2024_2025]: '2025',
}

export const NEXT_SURVEY_OPEN_DATES = {
    [SURVEY_YEAR_2023]: 'mid-to-late April 2023',
    [SURVEY_YEAR_2023_2024]: 'mid-to-late October 2023',
    [SURVEY_YEAR_2024]: 'April 29, 2024',
    [SURVEY_YEAR_2024_2025]: 'early October 2024',
}

export const NEXT_SURVEY_PARTICIPATION_LINKS = {
    [SURVEY_YEAR_2024_2025]: 'https://ww3.culpepper.com/SBS/2025/Q/',
}

export const EXCEL_TABLE_AVAILABLE_SURVEYS = [
    SURVEY_YEAR_2022_2023,
    SURVEY_YEAR_2023,
    SURVEY_YEAR_2023_2024,
    SURVEY_YEAR_2024,
]

export const SURVEY_YEAR_FOR_LAST_AVAILABLE_HISTORICAL_YEAR = {
    [SURVEY_YEAR_2022_2023]: 2023,
    [SURVEY_YEAR_2023_PRELIM]: 2023,
    [SURVEY_YEAR_2023]: 2023,
    [SURVEY_YEAR_2023_2024]: 2024,
    [SURVEY_YEAR_2024]: 2024,
    [SURVEY_YEAR_2024_2025]: 2025,
}

export const SURVEY_PUBLISH_DATE_LIST = {
    [SURVEY_YEAR_2022_2023]: 'August 29, 2022',
    [SURVEY_YEAR_2023_PRELIM]: 'February 14, 2023',
    [SURVEY_YEAR_2023]: 'March 3, 2023',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'August 3, 2023',
    [SURVEY_YEAR_2023_2024]: 'August 31, 2023',
    [SURVEY_YEAR_2024_PRELIM]: 'January 16, 2024',
    [SURVEY_YEAR_2024]: 'March 1, 2024',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'August 5, 2024',
    [SURVEY_YEAR_2024_2025]: 'August 30, 2024',
}

export const SURVEY_FINAL_RESULTS_PUBLISH_DATE_LIST = {
    [SURVEY_YEAR_2023_PRELIM]: 'March 3, 2023',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'August 31, 2023',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'August 31, 2024',
}

export const SURVEY_PUBLISH_MONTH_LIST = {
    [SURVEY_YEAR_2022_2023]: 'August 2022',
    [SURVEY_YEAR_2023]: 'March 2023',
    [SURVEY_YEAR_2023_2024]: 'August 2023',
    [SURVEY_YEAR_2024]: 'March 2024',
}

export const SURVEY_COPY_RIGHT = {
    [SURVEY_YEAR_2022_2023]:
        'Source: 2022-2023 Culpepper Salary Budget & Compensation Planning Survey, August 2022 © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2023_PRELIM]:
        'Source: 2023 Culpepper Salary Budget & Compensation Planning Survey (Preliminary High-Level Results, ' +
        SURVEY_PUBLISH_DATE_LIST[SURVEY_YEAR_2023_PRELIM] +
        ') © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2023]:
        'Source: 2023 Culpepper Salary Budget & Compensation Planning Survey, March 2023 © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2023_2024_PRELIM]:
        'Source: 2023-2024 Culpepper Salary Budget & Compensation Planning Survey (Preliminary High-Level Results, ' +
        SURVEY_PUBLISH_DATE_LIST[SURVEY_YEAR_2023_2024_PRELIM] +
        ') © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2023_2024]:
        'Source: 2023-2024 Culpepper Salary Budget & Compensation Planning Survey, August 2023 © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2024_PRELIM]:
        'Source: 2024 Culpepper Salary Budget & Compensation Planning Survey (Preliminary High-Level Results, ' +
        SURVEY_PUBLISH_DATE_LIST[SURVEY_YEAR_2024_PRELIM] +
        ') © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2024]:
        'Source: 2024 Culpepper Salary Budget & Compensation Planning Survey, March 2024 © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2024_2025_PRELIM]:
        'Source: 2024-2025 Culpepper Salary Budget & Compensation Planning Survey (Preliminary High-Level Results, ' +
        SURVEY_PUBLISH_DATE_LIST[SURVEY_YEAR_2024_2025_PRELIM] +
        ') © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2024_2025]:
        'Source: 2024-2025 Culpepper Salary Budget & Compensation Planning Survey, August 2024 © Culpepper and Associates, Inc.',
}

export const SURVEY_COPY_RIGHT_HISTORY = {
    [SURVEY_YEAR_2022_2023]:
        'Source: 2022-2023 Culpepper Salary Budget & Compensation Planning Survey © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2023]:
        'Source: 2023 Culpepper Salary Budget & Compensation Planning Survey © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2023_2024]:
        'Source: 2023-2024 Culpepper Salary Budget & Compensation Planning Survey © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2024]:
        'Source: 2024 Culpepper Salary Budget & Compensation Planning Survey © Culpepper and Associates, Inc.',
    [SURVEY_YEAR_2024_2025]:
        'Source: 2024-2025 Culpepper Salary Budget & Compensation Planning Survey © Culpepper and Associates, Inc.',
}

export const FINAL_RESULTS_AVAILABLE_DATE = {
    [SURVEY_YEAR_2023_PRELIM]: 'March 6, 2023',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'August 31, 2023',
    [SURVEY_YEAR_2024_PRELIM]: 'March 1, 2024',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'August 31, 2024',
}

export const SURVEY_ORG_NUMBER_LIST = {
    [SURVEY_YEAR_2022_2023]: 1645,
    [SURVEY_YEAR_2023_PRELIM]: 581,
    [SURVEY_YEAR_2023]: 1187,
    [SURVEY_YEAR_2023_2024_PRELIM]: 1087,
    [SURVEY_YEAR_2023_2024]: 1654,
    [SURVEY_YEAR_2024_PRELIM]: 1803,
    [SURVEY_YEAR_2024]: 1985,
    [SURVEY_YEAR_2024_2025_PRELIM]: 1192,
    [SURVEY_YEAR_2024_2025]: 1969,
}
export const SURVEY_AVAIL_COUNTRY_NUMBER_LIST = {
    [SURVEY_YEAR_2022_2023]: 169,
    [SURVEY_YEAR_2023_PRELIM]: 169,
    [SURVEY_YEAR_2023]: 169,
    [SURVEY_YEAR_2023_2024_PRELIM]: 169,
    [SURVEY_YEAR_2023_2024]: 169,
    [SURVEY_YEAR_2024_PRELIM]: 169,
    [SURVEY_YEAR_2024]: 169,
    [SURVEY_YEAR_2024_2025_PRELIM]: 169,
    [SURVEY_YEAR_2024_2025]: 163,
}

export const CURRENT_YEAR_LIST = {
    [SURVEY_YEAR_2022_2023]: 2022,
    [SURVEY_YEAR_2023_PRELIM]: 2022,
    [SURVEY_YEAR_2023]: 2022,
    [SURVEY_YEAR_2023_2024_PRELIM]: 2023,
    [SURVEY_YEAR_2023_2024]: 2023,
    [SURVEY_YEAR_2024_PRELIM]: 2023,
    [SURVEY_YEAR_2024]: 2023,
    [SURVEY_YEAR_2024_2025_PRELIM]: 2024,
    [SURVEY_YEAR_2024_2025]: 2024,
}

export const UPCOMING_YEAR_LIST = {
    [SURVEY_YEAR_2022_2023]: 2023,
    [SURVEY_YEAR_2023_PRELIM]: 2023,
    [SURVEY_YEAR_2023]: 2023,
    [SURVEY_YEAR_2023_2024_PRELIM]: 2024,
    [SURVEY_YEAR_2023_2024]: 2024,
    [SURVEY_YEAR_2024_PRELIM]: 2024,
    [SURVEY_YEAR_2024]: 2024,
    [SURVEY_YEAR_2024_2025_PRELIM]: 2025,
    [SURVEY_YEAR_2024_2025]: 2025,
}

export const SURVEY_OLDEST_SUBMISSION_DATE = {
    [SURVEY_YEAR_2022_2023]: 'May 3, 2022',
    [SURVEY_YEAR_2023_PRELIM]: 'November 15, 2022',
    [SURVEY_YEAR_2023]: 'November 15, 2022',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'April 25',
    [SURVEY_YEAR_2023_2024]: 'April 25',
    [SURVEY_YEAR_2024_PRELIM]: 'April 25, 2023',
    [SURVEY_YEAR_2024]: 'April 25, 2023',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'May 1',
    [SURVEY_YEAR_2024_2025]: 'May 1',
}

export const SURVEY_MOST_RECENT_SUBMISSION_DATE = {
    [SURVEY_YEAR_2022_2023]: 'August 17, 2022',
    [SURVEY_YEAR_2023_PRELIM]: 'February 9, 2023',
    [SURVEY_YEAR_2023]: 'February 21, 2023',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'July 24, 2023',
    [SURVEY_YEAR_2023_2024]: 'August 16, 2023',
    [SURVEY_YEAR_2024_PRELIM]: 'January 8, 2024',
    [SURVEY_YEAR_2024]: 'February 12, 2024',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'July 31, 2024',
    [SURVEY_YEAR_2024_2025]: 'August 14, 2024',
}

export const SURVEY_RESULTS_PDF_FILE_LIST = {
    [SURVEY_YEAR_2022_2023]: 'Culpepper20222023SalaryBudgetSurveyReport.pdf',
    [SURVEY_YEAR_2023_PRELIM]: 'blank.pdf',
    [SURVEY_YEAR_2023]: 'Culpepper2023SalaryBudgetSurveyReport.pdf',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'blank.pdf',
    [SURVEY_YEAR_2023_2024]: 'Culpepper20232024SalaryBudgetSurveyReport.pdf',
    [SURVEY_YEAR_2024_PRELIM]: 'blank.pdf',
    [SURVEY_YEAR_2024]: 'Culpepper2024SalaryBudgetSurveyReport.pdf',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'blank.pdf',
    [SURVEY_YEAR_2024_2025]: 'Culpepper20242025SalaryBudgetSurveyReport.pdf',
}

export const SURVEY_RESULTS_WORD_FILE_LIST = {
    [SURVEY_YEAR_2022_2023]: 'Culpepper20222023SalaryBudgetSurveyReport.docx',
    [SURVEY_YEAR_2023_PRELIM]: 'blank.docx',
    [SURVEY_YEAR_2023]: 'Culpepper2023SalaryBudgetSurveyReport.docx',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'blank.docx',
    [SURVEY_YEAR_2023_2024]: 'Culpepper20232024SalaryBudgetSurveyReport.docx',
    [SURVEY_YEAR_2024_PRELIM]: 'blank.docx',
    [SURVEY_YEAR_2024]: 'Culpepper2024SalaryBudgetSurveyReport.docx',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'blank.docx',
    [SURVEY_YEAR_2024_2025]: 'Culpepper20242025SalaryBudgetSurveyReport.docx',
}

export const SURVEY_RESULTS_EXCEL_FILE_LIST = {
    [SURVEY_YEAR_2022_2023]:
        'Culpepper20222023SalaryBudgetSurveyDataTables.xlsx',
    [SURVEY_YEAR_2023_PRELIM]: 'blank.xlsx',
    [SURVEY_YEAR_2023]: 'Culpepper2023SalaryBudgetSurveyDataTables.xlsx',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'blank.xlsx',
    [SURVEY_YEAR_2023_2024]:
        'Culpepper20232024SalaryBudgetSurveyDataTables.xlsx',
    [SURVEY_YEAR_2024_PRELIM]: 'blank.xlsx',
    [SURVEY_YEAR_2024]: 'Culpepper2024SalaryBudgetSurveyDataTables.xlsx',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'blank.xlsx',
    [SURVEY_YEAR_2024_2025]: 'blank.xlsx',
}

export const SURVEY_QUESTIONNAIRE_FILE_LIST = {
    [SURVEY_YEAR_2022_2023]:
        'Culpepper20222023SalaryBudgetSurveyPreviewQuestionnaire.pdf',
    [SURVEY_YEAR_2023_PRELIM]: 'blank.pdf',
    [SURVEY_YEAR_2023]:
        'Culpepper2023SalaryBudgetSurveyPreviewQuestionnaire.pdf',
    [SURVEY_YEAR_2023_2024_PRELIM]: 'blank.pdf',
    [SURVEY_YEAR_2023_2024]:
        'Culpepper20232024SalaryBudgetSurveyPreviewQuestionnaire.pdf',
    [SURVEY_YEAR_2024_PRELIM]: 'blank.pdf',
    [SURVEY_YEAR_2024]:
        'Culpepper2024SalaryBudgetSurveyPreviewQuestionnaire.pdf',
    [SURVEY_YEAR_2024_2025_PRELIM]: 'blank.pdf',
    [SURVEY_YEAR_2024_2025]:
        'Culpepper20242025SalaryBudgetSurveyPreviewQuestionnaire.pdf',
}

// the constants listed below barely need updates
export const CONTACT_US_LINK = 'https://www.culpepper.com/ContactUs/Support'

export const PRIVACY_POLICY_LINK =
    'https://www.culpepper.com/AboutUs/PrivacyPolicy/'

export const DATA_CONFIDENTIALITY_LINK =
    'https://www.culpepper.com/AboutUs/Confidentiality/'

export const COOKIE_NOTICE_LINK = 'https://www.culpepper.com/CookieNotice/ '

export const REGION_SHEET_NAME = 'Global Overview'
export const COUNTRY_SHEET_NAME = 'Country Breakouts'
export const PRACTICE_SHEET_NAME = 'Practices'
export const HISTORICAL_SHEET_NAME = 'Historical'

export const CURRENT_YEAR = new Date().getFullYear()
export const FIRST_HISTORICAL_YEAR = 2007

export const CULPEPPER_COME_LINK = 'https://www.culpepper.com'

export const SBS_LOCAL_DEV_PAGE_ADDRESS = 'http://localhost:3000/'
export const SBS_DEV_PAGE_ADDRESS = 'https://sbs-dev.culpepper.com/'
export const SBS_PROD_PAGE_ADDRESS = 'https://sbs.culpepper.com/'

export const BANNER_SURVEY_NAME_SHORT_NO_CULPEPPER = 'Salary Budget Survey'

export const SURVEY_RESULT_PDF_FILE_TEXT_TITLE = 'Overview Report'
export const SURVEY_RESULT_WORD_FILE_TEXT_TITLE =
    'Overview Report - WORD VERSION (AVAILABLE ON DEV ONLY)'
export const SURVEY_RESULT_EXCEL_FILE_TEXT_TITLE = 'Overview Data Tables'
export const SURVEY_QUESTIONNAIRE_PDF_FILE_TEXT_TITLE = 'Survey Questionnaire'

export const CURRENT_YEAR_TEXT = 'Actual'
export const UPCOMING_YEAR_TEXT = 'Projected'

export const SALARY_FREEZE_BUTTON_TEXT = " Include Salary Freezes (0's)"

export const REGION_SELECTED_TEXT = 'Region'
export const COUNTRY_SELECTED_TEXT = 'Country'
export const UNAVAIL_DATA_BUTTON_TEXT = ' Show Rows with Unavailable Data'
export const HISTORICAL_CHECK_BOX_TOOLTIP_TEXT =
    "Historical Data is only available with report option - Include Salary Freeze (0's)"

export const REGION_DEFAULT_SELECTION = '/region?region=Global&alldata=No'
export const COUNTRY_DEFAULT_SELECTION =
    '/country?alldata=No&country=United%20States&report=Base%20Salary%20Increases'
export const PRACTICE_DEFAULT_SELECTION = {
    [SURVEY_YEAR_2022_2023]:
        '/practice?report=BasePayPhilosophy&country=Global',
    [SURVEY_YEAR_2023]: '/practice?report=BasePayPhilosophy&country=Global',
    [SURVEY_YEAR_2023_2024]:
        '/practice?report=ReviewFrequency_Select&country=Global',
    [SURVEY_YEAR_2024]:
        '/practice?report=ReviewFrequency_Select&country=Global',
    [SURVEY_YEAR_2024_2025]:
        '/practice?report=ReviewFrequency_Select&country=Global',
}
export const HISTORICAL_DEFAULT_SELECTION = '/historical?region=Global'
export const DEFINITIONS_DEFAULT_SELECTION = '/definitions'
export const BREAKPOINT_SM = 750

export const FOOTER_COLUMN_SPAN_NUMBER = 30
export const MIN_SAMPLE_SIZE = 10

export const DICT_KEY_COUNT = 'count'
export const DICT_KEY_PERCENT = 'percent'
export const DICT_KEY_GENRE = 'genre'

export const TEXT_ALL_DATA = 'All Data'
export const TEXT_NO_AVAIL_DATA =
    'There is insufficient data available for the selected options. Please remove or change selected options.'
export const TEXT_SELECT_JOB_FUNCTION =
    'Please select a job function from the dropdown.'
export const TEXT_SELECT_YEAR = 'Please select a year from the dropdown.'

export const REPORT_COLUMN_SELECTION_BUTTON_TEXT = 'Set Report Output Metrics'

export const TEXT_COUNTRY_SELECTION = 'Select Country:'
export const TEXT_REPORT_SELECTION = 'Select Report Type:'
export const TEXT_REGION_REPORT_SELECTION = 'Select Region:'
export const TEXT_YEAR_REPORT_SELECTION = 'Select Year:'
export const TEXT_YEAR_REPORT_SELECTION_HISTORICAL = 'Select Year(s):'
export const TEXT_ONE_DATA_CUT_SELECTION = 'Select a Data Cut (Optional):'
export const TEXT_DATA_CUTS_SELECTION = 'Select Data Cuts (Optional):'
export const TEXT_OTHER_OPTIONS = 'Other Options:'
export const TEXT_OTHER_OPTION = 'Other Option:'
export const TEXT_INDUSTRY_SECTOR = 'Industry Sector:'
export const TEXT_INDUSTRY_VERTICAL = 'Industry Vertical: '
export const TEXT_OWNERSHIP = 'Ownership: '
export const TEXT_HEADCOUNT = 'Headcount: '
export const TEXT_STARTUP = 'Startup Companies: '

export const TEXT_INSUFFICIENT_DATA = '* Insufficient Data'
export const TEXT_ANNOTATION_FOR_N_REGION =
    'N: The number of responses for a particular region, country, and data cut. Regional aggregates may include companies that submitted data for multiple countries.'
export const TEXT_ANNOTATION_FOR_N_COUNTRY =
    'N: The number of responses for a particular country and data cut.'

export const REPORT_LOADING_MSG = 'Loading report types'
export const TEXT_SELECT_PLACEHOLDER = '-'
export const BOOLEAN_TEXT_TRUE = 'Yes'
export const BOOLEAN_TEXT_FALSE = 'No'

export const YEAR_DATA_CUT_SELECTED_NAME = 'Year:'
export const JOB_FUNCTION_DATA_CUT_SELECTED_NAME = 'Job Function:'

export const SURVEY_TITLE_TEXT_NO_CULPEPPER_PRELIM =
    'Salary Budget & Compensation Planning Survey'
export const SURVEY_TITLE_TEXT_NO_CULPEPPER_FINAL =
    'Salary Budget & Compensation Planning Survey Final Results'

export const SURVEY_TITLE_TEXT =
    'Culpepper Salary Budget & Compensation Planning Survey'

export const SOURCE_PRELIM_ANNOTATION_TEXT = 'Preliminary High-Level Results'
export const HOME_PAGE_PRELIM_ANNOTATION_TEXT = 'Preliminary high-level results'
export const SOURCE_FINAL_ANNOTATION_TEXT = 'Final results'

export const SOURCE_CULPEPPER_ANNOTATION_TEXT =
    '© Culpepper and Associates, Inc.'

export const PRELIM_HOME_PAGE_REGION_SHEET_NAME =
    'View preliminary high-level results'

export const TABLE_COLUMN_NAME_N = 'N'
export const TABLE_COLUMN_NAME_GROUP = 'Category'
export const TABLE_COLUMN_NAME_MEAN = 'Mean (Average)'
export const TABLE_COLUMN_NAME_MEDIAN = 'Median 50th %tile'
export const TABLE_COLUMN_NAME_MODE = 'Mode'
export const TABLE_COLUMN_NAME_10th = '10th %tile'
export const TABLE_COLUMN_NAME_25th = '25th %tile'
export const TABLE_COLUMN_NAME_33rd = '33rd %tile'
export const TABLE_COLUMN_NAME_40th = '40th %tile'
export const TABLE_COLUMN_NAME_60th = '60th %tile'
export const TABLE_COLUMN_NAME_67th = '67th %tile'
export const TABLE_COLUMN_NAME_75th = '75th %tile'
export const TABLE_COLUMN_NAME_90th = '90th %tile'

export const COLUMN_VALUE_N = 'N'
export const COLUMN_VALUE_GROUP = 'Category'
export const COLUMN_VALUE_MEAN = 'Mean'
export const COLUMN_VALUE_MEDIAN = 'Median'
export const COLUMN_VALUE_MODE = 'Mode'
export const COLUMN_VALUE_10th = '10th'
export const COLUMN_VALUE_25th = '25th'
export const COLUMN_VALUE_33rd = '33rd'
export const COLUMN_VALUE_40th = '40th'
export const COLUMN_VALUE_60th = '60th'
export const COLUMN_VALUE_67th = '67th'
export const COLUMN_VALUE_75th = '75th'
export const COLUMN_VALUE_90th = '90th'

export const POP_UP_MENU_COLUMN_NAME_10th = '10th (%tile)'
export const POP_UP_MENU_COLUMN_NAME_25th = '25th (%tile)'
export const POP_UP_MENU_COLUMN_NAME_33rd = '33rd (%tile)'
export const POP_UP_MENU_COLUMN_NAME_40th = '40th (%tile)'
export const POP_UP_MENU_COLUMN_NAME_60th = '60th (%tile)'
export const POP_UP_MENU_COLUMN_NAME_67th = '67th (%tile)'
export const POP_UP_MENU_COLUMN_NAME_75th = '75th (%tile)'
export const POP_UP_MENU_COLUMN_NAME_90th = '90th (%tile)'
export const POP_UP_MENU_COLUMN_NAME_MEDIAN = 'Median (50th %tile)'
export const POP_UP_MENU_COLUMN_NAME_MEAN = 'Mean (Average)'
export const POP_UP_MENU_COLUMN_NAME_MODE = 'Mode'
